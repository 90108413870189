import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface State {
    showNoAppointment: boolean;
    distance?: number;
    appointmentType: {
        first_dose: boolean;
        second_dose: boolean;
    };
    vaccineType: {
        moderna: boolean;
        pfizer: boolean;
        'j&j': boolean; // Johnson & Johnson
        unknown: boolean;
    };
    pharmacy: string[];
}

export const initialState: State = {
    showNoAppointment: false,
    distance: undefined,
    appointmentType: {
        first_dose: false,
        second_dose: false
    },
    vaccineType: {
        moderna: false,
        pfizer: false,
        'j&j': false,
        unknown: false
    },
    pharmacy: []
};

type AppointmentType = typeof initialState.appointmentType;
type VaccineType = typeof initialState.vaccineType;

const vaccineFilter = createSlice({
    name: 'vaccineFilter',
    initialState,
    reducers: {
        toggleShowNoAppointment: (state) => ({
            ...state,
            showNoAppointment: !state.showNoAppointment
        }),
        updateDistance: (state, { payload: distance }: PayloadAction<number>) => {
            state.distance = distance;
        },
        updateAppointmentType: (state, { payload: appointmentType }: PayloadAction<AppointmentType>) => {
            state.appointmentType = appointmentType;
        },
        updateVaccineType: (state, { payload: vaccineType }: PayloadAction<VaccineType>) => {
            state.vaccineType = vaccineType;
        },
        updatePharmacy: (state, { payload: pharmacy }: PayloadAction<string[]>) => {
            state.pharmacy = pharmacy;
        },
        clearShowNoAppointment: (state) => {
            state.showNoAppointment = false;
        },
        clearDistance: (state) => {
            state.distance = undefined;
        },
        clearAppointmentType: (state, { payload: appointmentType }: PayloadAction<keyof AppointmentType>) => {
            state.appointmentType[appointmentType] = false;
        },
        clearVaccineType: (state, { payload: vaccineType }: PayloadAction<keyof VaccineType>) => {
            state.vaccineType[vaccineType] = false;
        },
        clearPharmacy: (state, { payload: pharmacy }: PayloadAction<string>) => {
            state.pharmacy = state.pharmacy.filter((p) => p !== pharmacy);
        },
        clearAll: (state) => {
            return initialState;
        }
    }
});

export const VaccineFilterAction = vaccineFilter.actions;

export default vaccineFilter;
