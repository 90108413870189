import { createSlice } from '@reduxjs/toolkit';
import { CovidResources } from '../../../api/covid19UHC';
import { fetchCovidResources } from './effects';

export interface State {
    data?: CovidResources;
    loading: boolean;
    error?: string;
}

export const initialState: State = {
    loading: false
};

const additionalResources = createSlice({
    name: 'additionalResources',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCovidResources.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload;
        });
        builder.addCase(fetchCovidResources.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchCovidResources.rejected, (state, action) => {
            state.loading = false;
            state.error = JSON.stringify(action.payload);
        });
    }
});

export const AdditionalResourcesActions = additionalResources.actions;

export default additionalResources;
