import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Coordinates, StateCode } from '../../../api/location';
import { TestingLocation } from '../../../api/testingLocations';

export interface TypeaheadAddress {
    city: string;
    state: StateCode;
}

export interface TypeaheadLocation {
    name: string;
    coords: Coordinates;
    address: TypeaheadAddress;
}

export interface State {
    results: TestingLocation[];
    searchRadius?: number;
    retrievalInProgress: boolean;
    lastLocationSearch?: TypeaheadLocation;
    resultsRetrievalCount: number;
}

export const initialState: State = {
    results: [],
    searchRadius: undefined,
    retrievalInProgress: false,
    lastLocationSearch: undefined,
    resultsRetrievalCount: 0
};

const testingLocations = createSlice({
    name: 'testingLocations',
    initialState,
    reducers: {
        setResults: (state, { payload }: PayloadAction<any>) => ({
            ...state,
            results: payload,
            resultsRetrievalCount: state.resultsRetrievalCount + 1
        }),
        setRadius: (state, { payload }: PayloadAction<number | undefined>) => ({ ...state, searchRadius: payload }),
        setRetrievalInProgress: (state, { payload }: PayloadAction<boolean>) => ({
            ...state,
            retrievalInProgress: payload
        }),
        setLastLocationSearch: (state, { payload }: PayloadAction<TypeaheadLocation | undefined>) => ({
            ...state,
            lastLocationSearch: payload
        })
    }
});

export const { setResults, setRadius, setRetrievalInProgress, setLastLocationSearch } = testingLocations.actions;

export default testingLocations;
