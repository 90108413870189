import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Response {
    questionKey: number;
    response: string;
    nextQuestion: number;
}

export interface State {
    responses: { [key: string]: Response };
    resultsViewed: boolean;
}

export const initialState: State = {
    responses: {},
    resultsViewed: false
};

const survey = createSlice({
    name: 'survey',
    initialState,
    reducers: {
        setResponses: (state, { payload }: PayloadAction<Response>) => ({
            ...state,
            responses: { ...state.responses, [payload.questionKey]: payload }
        }),
        setResultsViewed: (state, { payload }: PayloadAction<boolean>) => ({ ...state, resultsViewed: payload }),
        clearResponses: () => initialState
    }
});

export const { setResponses, clearResponses, setResultsViewed } = survey.actions;

export default survey;
