import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EntryCollection } from 'contentful';

export interface State {
    content: EntryCollection<any> | Record<string, unknown>;
}

export const initialState: State = {
    content: {}
};

const cms = createSlice({
    name: 'cms',
    initialState,
    reducers: {
        setContent: (state, { payload }: PayloadAction<EntryCollection<any>>) => ({ ...state, content: payload })
    }
});

export const { setContent } = cms.actions;

export default cms;
