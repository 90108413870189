import * as React from 'react';
import styled, { keyframes } from 'styled-components';

const bounceKeyframe = keyframes`
    0%, 80%, 100% {
        transform: scale(0);
    } 40% {
        transform: scale(1.0);
    }
`;

const LoadingContainer = styled.div`
    margin: calc(50vh - 10px) auto;
    text-align: center;
    width: 100%;
`;

const Dot = styled.div`
    animation: ${bounceKeyframe} 1.4s infinite ease-in-out both;
    background: #333;
    border-radius: 100%;
    display: inline-block;
    height: 20px;
    margin: 0 5px;
    width: 20px;
    &:nth-child(1) {
        animation-delay: -0.32s;
    }
    &:nth-child(2) {
        animation-delay: -0.16s;
    }
`;

const Loading = () => (
    <LoadingContainer>
        <Dot />
        <Dot />
        <Dot />
    </LoadingContainer>
);

export default Loading;
