export interface RouteDefinition {
    [key: string]: {
        key: string; // unique key used to identify the route/state. ideally it should be camelcase.
        name: string; // the name of the route/state used for analytics. this value must be approved with product/data.
        path: string; // relative path of the route
        tags: string[]; // page 'tags' used for analytics. also must be approved.
        title: string; // title of the page, if applicable
    };
}

export const routes: RouteDefinition = {
    home: {
        key: 'home',
        name: 'CovidHome',
        path: '/',
        tags: [],
        title: 'COVID-19 Information Center | Rally Health'
    },
    survey: {
        key: 'symptomChecker',
        name: 'CovidSelfChecker',
        path: '/self-checker',
        tags: [],
        title: 'COVID-19 Self-Checker | Rally Health'
    },
    surveyResults: {
        key: 'surveyResults',
        name: 'CovidSelfCheckerResults',
        path: '/results',
        tags: [],
        title: 'COVID-19 Survey Results | Rally Health'
    },
    prevention: {
        key: 'prevention',
        name: 'PreventionAndSafety',
        path: '/prevention',
        tags: [],
        title: 'COVID-19 Prevention and Safety Tips | Rally Health'
    },
    getHelp: {
        key: 'getHelp',
        name: 'GetHelp',
        path: '/get-help',
        tags: [],
        title: 'Get Help | Rally Health'
    },
    overview: {
        key: 'overview',
        name: 'CovidOverview',
        path: '/overview',
        tags: [],
        title: 'COVID 101 | Rally Health'
    },
    testingLocations: {
        key: 'testingLocations',
        name: 'TestingLocations',
        path: '/testing-locations',
        tags: [],
        title: 'Find a COVID-19 Testing Location | Rally Health'
    },
    testingLocationDetail: {
        key: 'testingLocationDetail',
        name: 'TestingLocationDetail',
        path: '/testing-locations/detail',
        tags: [],
        title: 'COVID-19 Testing Location Detail | Rally Health'
    },
    vaccineLocations: {
        key: 'vaccineLocations',
        name: 'VaccineLocations',
        path: '/vaccine-locations',
        tags: [],
        title: 'Find a COVID-19 Vaccine Location | Rally Health'
    },
    vaccineLocationDetail: {
        key: 'vaccineLocationDetail',
        name: 'VaccineLocationDetail',
        path: '/vaccine-locations/detail/:state/:id',
        tags: [],
        title: 'COVID-19 Vaccine Location Detail | Rally Health'
    }
};
