import { Suspense, FC } from 'react';
import DocumentTitle from 'react-document-title';
import Loading from '../Loading';

interface Props {
    title: string;
}

const SuspensePage: FC<Props> = ({ title, children }) => (
    <DocumentTitle title={title}>
        <Suspense fallback={<Loading />}>{children}</Suspense>
    </DocumentTitle>
);

export default SuspensePage;
