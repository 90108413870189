interface Config {
    [key: string]: any;
}
export class NeptuneConfigs {
    configs: Config;
    constructor() {
        const $window = window as any;
        this.configs = {
            ...$window.CONFIG,
            ...$window.FEATURE_FLAGS,
            ...$window.API_KEYS
        };
    }
    get = <T>(key: string, defaultValue: T): T => {
        return Object.prototype.hasOwnProperty.call(this.configs, key) ? this.configs[key] : defaultValue;
    };
}
