import { combineReducers } from '@reduxjs/toolkit';
import survey from './survey';
import user from './user';
import cms from './cms';
import testingLocations from './testingLocations';
import vaccineLocations from './vaccineLocations';
import currentLocation from './currentLocation';
import i18nSlice from './i18n';
import vaccineFilter from './vaccineFilter';
import additionalResources from './additionalResources';

const rootReducer = combineReducers({
    survey: survey.reducer,
    user: user.reducer,
    cms: cms.reducer,
    testingLocations: testingLocations.reducer,
    vaccineLocations: vaccineLocations.reducer,
    currentLocation: currentLocation.reducer,
    i18n: i18nSlice.reducer,
    vaccineFilter: vaccineFilter.reducer,
    additionalResources: additionalResources.reducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
