import { ReactComponentElement } from 'react';
import { ReactComponent as RallyLogo } from '../img/rally-logo.svg';
import { ReactComponent as OptumLogo } from '../img/optum-logo.svg';
import { ReactComponent as UHCLogo } from '../img/uhc-logo.svg';
import { ReactComponent as RallyLogoMobile } from '../img/rally-logo-mobile.svg';
import { ReactComponent as OptumLogoMobile } from '../img/optum-logo-mobile.svg';
import { ReactComponent as UHCLogoMobile } from '../img/uhc-logo-mobile.svg';

export interface Theme {
    [key: string]: string | ReactComponentElement<any>;
}

export enum SupportedPartners {
    rally = 'rally',
    optum = 'optum'
}

export const Defaults = {
    appBackground: '#F7F7F7',
    availableIcon: '#4AC37D',
    barelyVisible: '#FAFAFA',
    defaultBorderColor: '#E5E5E5',
    defaultCardBackground: '#FFFFFF',
    defaultTextColor: '#333333',
    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
    iconBackgroundColorCDC: '#f9e8e0',
    iconBackgroundColorReading: '#dbf3e5',
    iconBackgroundColorTesting: '#ffcb61',
    iconBackgroundColorVaccine: '#ccf0f9',
    inputLabel: '#757588',
    interactiveTextColor: '#196ECF',
    logo: RallyLogo,
    logoMobile: RallyLogoMobile,
    logoLink: 'https://www.rallyhealth.com',
    madeByRallyDisplay: 'none',
    mapPins: '#FF5F0E',
    mapPinsHover: '#B14F25',
    middleGray: '#CCCCCC',
    navLink: 'https://www.rallyhealth.com/covid-19',
    navLinkHoverColor: '#F26C32',
    navLinkText: 'COVID-19 Resource Center',
    notAvailableIcon: '#ED5C4B',
    partnerName: 'Rally',
    placeholderBackground: '#D8D8D8',
    primaryCta: '#49C57E',
    primaryCtaBorder: '#3FAB6D',
    showLegalCopyV2: true,
    skeletonGray: '#EEEEEE',
    subheaderGray: '#666666',
    formPrimary: '#007897'
};

export type ThemeType = typeof Defaults;

export const Optum = {
    ...Defaults,
    defaultTextColor: '#2D2D39',
    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
    logo: OptumLogo,
    logoMobile: OptumLogoMobile,
    logoLink: 'https://www.optum.com/',
    madeByRallyDisplay: 'block',
    mapPins: '#E87722',
    mapPinsHover: '#D06B1E',
    navLinkHoverColor: '#00396C',
    navLink: 'https://covid19.rallyhealth.com/embed/optum',
    navLinkText: 'Return home',
    partnerName: 'Optum',
    primaryCta: '#316BBE',
    primaryCtaBorder: '#27589c',
    showLegalCopyV2: false
};

export const United = {
    ...Defaults,
    defaultTextColor: '#2D2D39',
    fontFamily: '"UHC Sans", Arial, sans-serif',
    logo: UHCLogo,
    logoMobile: UHCLogoMobile,
    logoLink: 'https://www.uhc.com/',
    mapPins: '#003DA1',
    mapPinsHover: '#0D56B8',
    navLinkHoverColor: '#155CAD',
    navLink: 'https://www.uhc.com/',
    navLinkText: 'UnitedHealthcare Home',
    partnerName: 'UnitdHealthcare',
    primaryCta: '#196ECF',
    primaryCtaBorder: '#175BA7'
};

export const Themes = {
    [SupportedPartners.rally]: Defaults,
    [SupportedPartners.optum]: Optum
};
