import { SupportedLanguage } from './languageConstants';

const dateTimeFormat: Intl.DateTimeFormatOptions = {
    timeZoneName: 'short',
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour12: true,
    hour: 'numeric',
    minute: '2-digit'
};

const dateFormat: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
};

const timeFormat: Intl.DateTimeFormatOptions = {
    timeZoneName: 'short',
    hour12: true,
    hour: 'numeric',
    minute: '2-digit'
};

export const formatDateTime = (time: string, locale: SupportedLanguage) =>
    new Date(time).toLocaleString(locale, dateTimeFormat);
export const formatDate = (time: string, locale: SupportedLanguage) =>
    new Date(time).toLocaleString(locale, dateFormat);
export const formatTime = (time: string, locale: SupportedLanguage) =>
    new Date(time).toLocaleString(locale, timeFormat);
