import { createSlice } from '@reduxjs/toolkit';
import { Coordinates, StateCode } from '../../../api/location';
import { GEOGRAPHIC_CENTER_OF_US, LOCAL_ZOOM_LEVEL, NATIONAL_ZOOM_LEVEL } from '../../../utilities';
import { loadMapCoordinates, updateLocation } from './effects';
export interface State {
    zoomLevel: number;
    currentLocation: Coordinates;
    loading: boolean;
    error?: string;
    currentState?: StateCode;
    manuallySet: boolean;
}

export const initialState: State = {
    zoomLevel: NATIONAL_ZOOM_LEVEL,
    currentLocation: GEOGRAPHIC_CENTER_OF_US,
    loading: false,
    error: undefined,
    currentState: undefined,
    manuallySet: false
};

const currentLocation = createSlice({
    name: 'currentLocation',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(updateLocation.fulfilled, (state, { payload }) => {
            const { coordinates, state: stateCode, manuallySet } = payload;
            state.loading = false;
            state.currentState = stateCode;
            state.currentLocation = coordinates;
            state.zoomLevel = LOCAL_ZOOM_LEVEL;
            state.manuallySet = !!manuallySet;
        });
        builder.addCase(updateLocation.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateLocation.rejected, (state, action) => {
            state.loading = false;
            state.error = JSON.stringify(action.payload);
        });
        builder.addCase(loadMapCoordinates.fulfilled, (state, { payload }) => {
            if (payload.coordinates && payload.state) {
                state.currentLocation = payload.coordinates;
                state.currentState = payload.state;
            }
        });
        builder.addCase(loadMapCoordinates.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(loadMapCoordinates.rejected, (state, action) => {
            state.loading = false;
            state.error = JSON.stringify(action.payload);
        });
    }
});

export const CurrentLocationActions = currentLocation.actions;

export default currentLocation;
