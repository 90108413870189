import { lazy, useEffect, useState } from 'react';
import { routes } from './router/routeDefinitions';
import './styles/index.css';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
import NavBar from './components/nav/nav';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import styled from 'styled-components';
import { Themes, SupportedPartners } from './styles/themes';
import { GHOST_ENABLE_THEMES } from './neptuneConfigs/getConfig';
import DocumentTitle from 'react-document-title';
import SuspensePage from './components/suspensePage';
import { GHOST_FEATURE_VACCINE_LOCATIONS } from './neptuneConfigs/getConfig';

const Survey = lazy(() => import('./components/survey/survey'));
const SurveyResults = lazy(() => import('./components/surveyResults/surveyResults'));
const Welcome = lazy(() => import('./components/welcome/welcome'));
const Home = lazy(() => import('./components/home/home'));
const TestingLocations = lazy(() => import('./components/testingLocations/testingLocations'));
const TestingLocationDetail = lazy(() => import('./components/testingLocationDetail/testingLocationDetail'));
const VaccineLocations = lazy(() => import('./components/vaccineLocations/vaccineLocations'));
const VaccineLocationDetail = lazy(() => import('./components/vaccineLocationDetail/vaccineLocationDetail'));

const GlobalStyle = createGlobalStyle`
    body {
        background: ${({ theme }) => theme.appBackground};
        color: ${({ theme }) => theme.defaultTextColor};
        font-family: ${({ theme }) => theme.fontFamily};
        margin: 0;
    }
`;

const Content = styled.div`
    margin: 0 auto;
    padding-bottom: 60px;
`;

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function App() {
    const [themeName, setThemeName] = useState<'rally' | 'optum' | undefined>(undefined);
    const [embeddedMode, setEmbeddedMode] = useState(false);
    useEffect(() => {
        const pathComponents = window.location.pathname.split('/');
        const embedIndicator = pathComponents[1] === 'embed';
        const parsedThemePartner = pathComponents[embedIndicator ? 2 : 1] as 'rally' | 'optum';
        const sessionTheme =
            (GHOST_ENABLE_THEMES() && SupportedPartners[parsedThemePartner]) || SupportedPartners.rally;
        setEmbeddedMode(embedIndicator);
        setThemeName(sessionTheme);
    }, []);
    if (themeName === undefined) return null;
    const basePath = `${embeddedMode ? '/embed' : ''}${themeName !== SupportedPartners.rally ? `/${themeName}` : ''}`;
    return (
        <DocumentTitle title="COVID-19 Information Center | Rally Health">
            <Router basename={basePath !== '' ? basePath : undefined}>
                <ScrollToTop />
                <ThemeProvider theme={Themes[themeName]}>
                    <GlobalStyle />
                    <Content>
                        <SuspensePage title={routes.home.title}>
                            <NavBar embeddedMode={embeddedMode} home={basePath} />
                        </SuspensePage>
                        <div>
                            <Switch>
                                <Route path={`${routes.survey.path}/:id`}>
                                    <SuspensePage title={routes.survey.title}>
                                        <Survey />
                                    </SuspensePage>
                                </Route>
                                <Route path={routes.survey.path}>
                                    <SuspensePage title={routes.survey.title}>
                                        <Welcome />
                                    </SuspensePage>
                                </Route>
                                <Route path={routes.surveyResults.path}>
                                    <SuspensePage title={routes.surveyResults.title}>
                                        <SurveyResults />
                                    </SuspensePage>
                                </Route>
                                <Route path={routes.testingLocationDetail.path}>
                                    <SuspensePage title={routes.testingLocationDetail.title}>
                                        <TestingLocationDetail />
                                    </SuspensePage>
                                </Route>
                                <Route path={routes.testingLocations.path}>
                                    <SuspensePage title={routes.testingLocations.title}>
                                        <TestingLocations />
                                    </SuspensePage>
                                </Route>
                                {GHOST_FEATURE_VACCINE_LOCATIONS() && (
                                    <Route path={routes.vaccineLocationDetail.path}>
                                        <SuspensePage title={routes.vaccineLocationDetail.title}>
                                            <VaccineLocationDetail />
                                        </SuspensePage>
                                    </Route>
                                )}
                                {GHOST_FEATURE_VACCINE_LOCATIONS() && (
                                    <Route path={routes.vaccineLocations.path}>
                                        <SuspensePage title={routes.vaccineLocations.title}>
                                            <VaccineLocations />
                                        </SuspensePage>
                                    </Route>
                                )}
                                {/* Root path must come last as any path containing a slash will match it */}
                                <Route path={routes.home.path}>
                                    <SuspensePage title={routes.home.title}>
                                        <Home />
                                    </SuspensePage>
                                </Route>
                            </Switch>
                        </div>
                    </Content>
                </ThemeProvider>
            </Router>
        </DocumentTitle>
    );
}

export default App;
