import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VaccineLocationsResponse } from '../../../api/vaccineLocations';

export interface State {
    locationResults?: VaccineLocationsResponse;
    searchRadius?: number;
    retrievalInProgress: boolean;
    resultsRetrievalCount: number;
}

export const initialState: State = {
    locationResults: undefined,
    searchRadius: undefined,
    retrievalInProgress: false,
    resultsRetrievalCount: 0
};

const vaccineLocations = createSlice({
    name: 'vaccineLocations',
    initialState,
    reducers: {
        setResults: (state, { payload }: PayloadAction<{ locations: VaccineLocationsResponse | undefined }>) => ({
            ...state,
            locationResults: payload.locations,
            resultsRetrievalCount: state.resultsRetrievalCount + 1,
            retrievalInProgress: false
        }),
        setRadius: (state, { payload }: PayloadAction<number | undefined>) => ({ ...state, searchRadius: payload }),
        setRetrievalInProgress: (state, { payload }: PayloadAction<boolean>) => ({
            ...state,
            retrievalInProgress: payload
        })
    }
});

export const { setResults, setRadius, setRetrievalInProgress } = vaccineLocations.actions;

export default vaccineLocations;
