import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SupportedLanguage, getInitialLocale } from '../../../utilities/languageConstants';

// TODO: Do we need Redux or can we get the language from the i18n instance?
export interface State {
    locale: SupportedLanguage;
}

export const initialState: State = {
    locale: getInitialLocale()
};

const i18nSlice = createSlice({
    name: 'i18n',
    initialState,
    reducers: {
        changeLocale: (state, { payload }: PayloadAction<SupportedLanguage>) => ({ ...state, locale: payload })
    }
});

export const { changeLocale } = i18nSlice.actions;

export default i18nSlice;
