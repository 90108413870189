import axios, { AxiosPromise } from 'axios';
import { USStates } from '../utilities';

export type StateCode = keyof typeof USStates;

export type StateName = typeof USStates[StateCode];

export type Coordinates = [number, number];

export type PostalCode = Record<string, Coordinates>;

interface UserLocation {
    country: string;
    zip: string;
    city: string;
    state: StateCode;
    coords: number[];
}

interface StateNameResponse {
    stateName: StateName;
    stateNameShort: StateCode;
}
export const getUserLocation = (): AxiosPromise<UserLocation> => axios.get<UserLocation>('/rest/user/v1/user/location');

export const getStateByCoords = ([log, lat]: Coordinates): AxiosPromise<StateNameResponse> =>
    axios.get<StateNameResponse>(
        `/rest/geolocation/v1/location/geoCoords/stateName?geoCoords=${log},${lat}&requestMode=temp`
    );
