import {
    initAnalytics,
    AppPlatform,
    ConfigurationKeys,
    SupportedDestinationKeys,
    AppDataKeys,
    EnvironmentType
} from '@rally/analytics';
import amplitude from 'amplitude-js';
import { GHOST_KEYS_AMPLITUDE } from '../neptuneConfigs/getConfig';

export const initRallyAnalytics = () => {
    if (initAnalytics) {
        initAnalytics(
            {
                [SupportedDestinationKeys.Amplitude]: {
                    [ConfigurationKeys.Include]: true,
                    [ConfigurationKeys.Sdk]: amplitude
                }
            },
            {
                [AppDataKeys.AppName]: 'covid-web',
                [AppDataKeys.AppVersion]: '1.0',
                [AppDataKeys.AppPlatform]: AppPlatform.Web,
                [AppDataKeys.Environment]: EnvironmentType.Production,
                [AppDataKeys.Product]: 'COVID'
            } as any
        );
    }
};

export const initAmplitude = () => {
    if (amplitude) {
        let userId = localStorage.getItem('userId');
        amplitude.getInstance().init(GHOST_KEYS_AMPLITUDE(), undefined, { includeReferrer: true });
        if (!userId || userId === 'null') {
            userId = String(Math.floor(100000000 + Math.random() * 900000));
            localStorage.setItem('userId', userId);
        }
        amplitude.getInstance().setUserId(userId);
        initRallyAnalytics();
    }
};
