import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { getInitialLocale } from '../utilities/languageConstants';
import { GHOST_WEB_VERSION } from '../neptuneConfigs/getConfig';

// https://github.com/i18next/i18next-http-backend#backend-options
const options = {
    loadPath: `${process.env.PUBLIC_URL}/i18n/locales/{{lng}}/translation.json`,
    queryStringParams: { v: GHOST_WEB_VERSION() }
};

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        backend: options,
        fallbackLng: 'en-US',
        lng: getInitialLocale(),
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        }
    });

export default i18n;
