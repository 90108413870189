import axios, { AxiosPromise } from 'axios';

// more fields are available, but we aren't using most of them
export interface CovidResources {
    stateCodeDOHVacPageUrl: string | null;
    stateCodeDOHVacPageUrlDisplay: string | null;
    stateCodeDOHVacSchedulePageUrl: string | null;
    stateCodeDOHVacSchedulePageDisplay: string | null;
    countyDOHVacPageUrl: string | null;
    countyDOHVacPageDisplay: string | null;
    countyDohVaccineSchedule: {
        [key in string]: {
            countyDohVacSchUrl: string | null;
            countyDohVacSchDisplay: string | null;
        };
    };
}

export const getCovidResources = (zip: number): AxiosPromise<CovidResources[]> =>
    axios.get(`/rest/vaccine/info/v1/${zip}`);
