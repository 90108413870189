import { useHistory, useLocation } from 'react-router-dom';

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

/**
 * If you update AppointmentType, you MUST update isAppointmentType or else bad things will happn
 */
export type AppointmentType = 'first_dose' | 'second_dose';
export const isAppointmentType = (s: string): s is AppointmentType => {
    switch (s) {
        case 'first_dose':
        case 'second_dose':
            return true;
        default:
            return false;
    }
};

/**
 * If you update VaccineType, you MUST update isVaccineType or else bad things will happn
 */
export type VaccineType = 'moderna' | 'pfizer' | 'j&j' | 'unknown';
export const isVaccineType = (s: string): s is VaccineType => {
    switch (s) {
        case 'moderna':
        case 'pfizer':
        case 'j&j':
        case 'unknown':
            return true;
        default:
            return false;
    }
};

export type DistanceType = '0' | '1' | '5' | '15' | '25' | '50';
export const isDistanceType = (s: string): s is DistanceType => {
    switch (s) {
        case '0':
        case '1':
        case '5':
        case '15':
        case '25':
        case '50':
            return true;
        default:
            return false;
    }
};

export enum QueryParam {
    ShowNoAppointment = 'no',
    AppointmentType = 'apt',
    VaccineType = 'vac',
    Pharmacy = 'p',
    Distance = 'd',
    Long = 'long',
    Lat = 'lat'
}

export function useQueryParams() {
    const query = useQuery();
    const history = useHistory();

    const set = (param: QueryParam, value?: string) => {
        if (value === undefined) {
            query.delete(param);
        } else {
            query.set(param, value);
        }
        history.push({
            search: query.toString()
        });
    };

    const toggle = (param: QueryParam, value: string) => {
        const existing = query.getAll(param);
        if (existing.includes(value)) {
            query.delete(param);
            existing.forEach((p) => {
                if (p !== value) {
                    query.append(param, p);
                }
            });
        } else {
            query.append(param, value);
        }
        history.push({
            search: query.toString()
        });
    };

    const clearAll = () => {
        for (const param of Object.values(QueryParam)) {
            if (param !== QueryParam.Long && param !== QueryParam.Lat) {
                query.delete(param);
            }
        }

        history.push({
            search: query.toString()
        });
    };

    const distance = query.get(QueryParam.Distance);
    const parseDistance = (s: string | null): number | undefined => {
        if (s === null) {
            return undefined;
        } else if (!isDistanceType(s)) {
            return undefined;
        } else {
            return parseInt(s);
        }
    };
    const showNoAppointment = query.get(QueryParam.ShowNoAppointment);
    const parseShowNoAppointment = (s: string | null): boolean => {
        // default to false
        if (s === null) {
            return false;
        } else {
            return s === 'true';
        }
    };
    const lat = query.get(QueryParam.Lat);
    const long = query.get(QueryParam.Long);

    return {
        set,
        toggle,
        clearAll,
        showNoAppointment: parseShowNoAppointment(showNoAppointment),
        appointmentType: query.getAll(QueryParam.AppointmentType).filter(isAppointmentType),
        vaccineType: query.getAll(QueryParam.VaccineType).filter(isVaccineType),
        pharmacies: query.getAll(QueryParam.Pharmacy),
        distance: parseDistance(distance),
        coords:
            lat === null || long === null
                ? undefined
                : {
                      lat: parseFloat(lat),
                      long: parseFloat(long)
                  }
    };
}
