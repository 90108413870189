import * as React from 'react';
import { useLocation } from 'react-router-dom';
import * as styleConsts from '../../styles/constants';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import { ReactComponent as NavBack } from '../../img/nav-back.svg';
import { useHistory } from 'react-router-dom';
import LanguageSelector from './LanguageSelector';
import { GHOST_FEATURE_ENABLE_TRANSLATION } from '../../neptuneConfigs/getConfig';
import rallyLogo from '../../img/rallyLogo.png';

const Container = styled.nav`
    font-weight: 500;
    @media (max-width: ${styleConsts.$mobileWidthMax}) {
        position: sticky;
        top: 0;
        z-index: 1;
    }
`;

const Content = styled.div<{ embeddedMode: boolean }>`
    align-items: center;
    background: white;
    border-bottom: ${({ theme, embeddedMode }) => (embeddedMode ? 'none' : `1px solid ${theme.defaultBorderColor}`)};
    box-sizing: border-box;
    display: flex;
    height: 50px;
    justify-content: space-between;
    padding: ${({ embeddedMode }) => (embeddedMode ? '0 16px' : '0')};
    @media (min-width: ${styleConsts.$mobileWidthMax}) {
        height: 64px;
        justify-content: space-between;
        padding: 0 100px;
    }
`;

const StyledLink = styled.a`
    display: flex;
    text-decoration: none;
    #logo-desktop {
        display: none;
    }
    @media (min-width: ${styleConsts.$mobileWidthMax}) {
        #logo-desktop {
            display: block;
        }
        #logo-mobile {
            display: none;
        }
    }
`;

const MobileNav = styled.div`
    align-items: center;
    display: flex;
    height: 44px;
    width: 54px;
    > svg {
        margin-left: 20px;
    }
    @media (min-width: ${styleConsts.$mobileWidthMax}) {
        display: none;
    }
`;

const StyledNavBack = styled(NavBack)`
    cursor: pointer;
`;

const Buffer = styled.div`
    height: 44px;
    width: 54px;
    @media (min-width: ${styleConsts.$mobileWidthMax}) {
        display: none;
    }
`;

const LinkOut = styled.a<{ embeddedMode?: boolean }>`
    color: ${({ theme }) => theme.defaultTextColor};
    display: ${(theme) => (theme.embeddedMode ? 'block' : 'none')};
    font-size: 14px;
    text-decoration: none;
    transition: all 0.1s ease;
    @media (min-width: ${styleConsts.$mobileWidthMax}) {
        display: block;
    }
    &:hover {
        color: ${({ theme }) => theme.navLinkHoverColor};
    }
`;

const RightHandSide = styled.div`
    display: none;
    @media (min-width: ${styleConsts.$mobileWidthMax}) {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }
`;

interface Props {
    theme: DefaultTheme;
    embeddedMode: boolean;
    home: any;
}
const NavBar = ({ theme, embeddedMode, home }: Props) => {
    const history = useHistory();
    const currentPage = useLocation().pathname.split('/')[1];
    // Translations are currently enabled for the home page and for vaccine locations
    const showTranslations =
        GHOST_FEATURE_ENABLE_TRANSLATION() && (currentPage === '' || currentPage === 'vaccine-locations');
    const { logo: Logo } = theme;
    const isHome = window.location.pathname === home;
    if (embeddedMode && isHome) return null;
    return (
        <Container>
            <Content embeddedMode={embeddedMode}>
                {embeddedMode ? (
                    <LinkOut href={theme.navLink} embeddedMode={embeddedMode}>
                        {theme.navLinkText}
                    </LinkOut>
                ) : (
                    <>
                        <MobileNav>
                            <StyledNavBack onClick={history.goBack} />
                        </MobileNav>
                        <StyledLink href={theme.logoLink}>
                            <Logo id="logo-desktop" />
                            <img id="logo-mobile" src={rallyLogo} height="27px" alt="rally logo" />
                        </StyledLink>
                        <Buffer />
                        <RightHandSide>
                            {showTranslations && <LanguageSelector />}
                            <LinkOut href={theme.navLink}>{theme.navLinkText} &rsaquo;</LinkOut>
                        </RightHandSide>
                    </>
                )}
            </Content>
        </Container>
    );
};

export default withTheme(NavBar);
