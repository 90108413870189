export enum SupportedLanguage {
    English = 'en-US',
    Spanish = 'es-US'
}

export const LOCALE_KEY = 'GHOSTBUSTERS_LOCALE';

export const getInitialLocale = () => {
    return (window.localStorage.getItem(LOCALE_KEY) as SupportedLanguage) || SupportedLanguage.English;
};
