import * as React from 'react';
import ReactSelect, { ActionMeta } from 'react-select';
import 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { LOCALE_KEY, SupportedLanguage } from '../../utilities/languageConstants';
import { changeLocale } from '../../redux/reducers/i18n/index';
import { getLocale } from '../../redux/selectors/i18n/index';

const StyledSelect = styled(ReactSelect)`
    margin: 10px;
    width: 110px;

    & .Select__control {
        background-color: ${(props) => props.theme.appBackground};
        border: 1px solid transparent;
    }
    & .Select__control--is-focused {
        border: 1px solid ${(props) => props.theme.formPrimary} !important;
    }
    & .Select__placeholder,
    .Select__option,
    .Select__single-value {
        color: ${(props) => props.theme.formPrimary};
    }
    & .Select__indicator-separator {
        display: none;
    }
    & .Select__option {
        background-color: white;
    }
    & .Select__option--is-focused {
        background-color: ${(props) => props.theme.appBackground};
    }
`;

const displayLocale: { [key in SupportedLanguage]: Record<string, unknown> } = {
    'en-US': { value: 'en-US', label: 'English' },
    'es-US': { value: 'es-US', label: 'Español' }
};

const LanguageSelector = () => {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const currentLocale = useSelector(getLocale);
    const changeLanguageHandler = (newLang: SupportedLanguage) => {
        dispatch(changeLocale(newLang));
        window.localStorage.setItem(LOCALE_KEY, newLang);
        i18n.changeLanguage(newLang);
    };
    const changeHandler = (value: Record<string, unknown> | null, action: ActionMeta<Record<string, unknown>>) => {
        if (action.action === 'select-option' && value && value.value) {
            changeLanguageHandler(value.value as SupportedLanguage);
        }
    };

    return (
        <StyledSelect
            options={Object.values(displayLocale)}
            onChange={changeHandler}
            value={displayLocale[currentLocale]}
            classNamePrefix={'Select'}
        />
    );
};

export default LanguageSelector;
