import { createAsyncThunk } from '@reduxjs/toolkit';
import { getStateByCoords, Coordinates, StateCode } from '../../../api/location';
import { QueryParam } from '../../../hooks/useQueryParams';
interface Payload {
    coordinates: Coordinates;
    state?: StateCode;
    manuallySet?: boolean;
}
export const updateLocation = createAsyncThunk(
    'currentLocation/updateLocation',
    async ({ coordinates, state, manuallySet }: Payload) => {
        if (state === undefined) {
            state = (await getStateByCoords(coordinates)).data.stateNameShort;
        }
        return { coordinates, state, manuallySet };
    }
);

export const loadMapCoordinates = createAsyncThunk('map/loadMapCoordinates', async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const long = urlParams.get(QueryParam.Long);
    const lat = urlParams.get(QueryParam.Lat);

    if (lat && long) {
        const coordinates: Coordinates = [parseFloat(long), parseFloat(lat)];
        const { data } = await getStateByCoords(coordinates);
        return { coordinates, state: data.stateNameShort };
    } else {
        const position = await getPosition();
        const coordinates: Coordinates = [position.coords.longitude, position.coords.latitude];
        const { data } = await getStateByCoords(coordinates);

        return { coordinates, state: data.stateNameShort };
    }
});

function getPosition() {
    return new Promise((resolve: PositionCallback, reject: PositionErrorCallback) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
    });
}
