import { NeptuneConfigs } from './index';

const Configs = new NeptuneConfigs();

export const GHOST_KEYS_AMPLITUDE = (): string =>
    Configs.get('GHOST_KEYS_AMPLITUDE', 'a1e0bdd955ff808fae9ce831fbec1afc');

export const GHOST_KEYS_MAPBOX = (): string =>
    Configs.get(
        'GHOST_KEYS_MAPBOX',
        'pk.eyJ1IjoicmFsbHloZWFsdGgiLCJhIjoiY2s4aTdkNHgwMDM5czNscW1kcHpsMGJtbCJ9.R4UPp8U5PrHMC_VgSlow4w'
    );

export const GHOST_FEATURE_VACCINE_LOCATIONS = (): boolean =>
    window.location.pathname.split('/')[1] !== 'embed' &&
    Configs.get<boolean>('GHOST_FEATURE_VACCINE_LOCATIONS', process.env.NODE_ENV !== 'production');

export const GHOST_ENABLE_THEMES = (): boolean => Configs.get('GHOST_ENABLE_THEMES', true);

export const GHOST_WEB_VACCINE_DATA_ROOT = (): string => Configs.get('GHOST_WEB_VACCINE_DATA_ROOT', '/static/api/v0');

// Default to current timestamp for local dev environment.
// For  all other envs, GHOST_WEB_VERSION will be the app version.
export const GHOST_WEB_VERSION = (): string => Configs.get('GHOST_WEB_VERSION', `${Date.now()}`);

export const GHOST_FEATURE_ENABLE_TRANSLATION = (): boolean => Configs.get('GHOST_FEATURE_ENABLE_TRANSLATION', true);

export const GHOST_FEATURE_VACCINE_FILTER = (): boolean =>
    GHOST_FEATURE_VACCINE_LOCATIONS() && Configs.get<boolean>('GHOST_FEATURE_VACCINE_FILTER', true);

export const GHOST_FEATURE_BACK_TO_TOP_HOVER = (): boolean => Configs.get('GHOST_FEATURE_BACK_TO_TOP_HOVER', true);

export const GHOST_FEATURE_SHOW_RIDESHARE = (): boolean => Configs.get('GHOST_FEATURE_SHOW_RIDESHARE', true);

export const GHOST_FEATURE_SHOW_SOCIAL_SHARE = (): boolean => Configs.get('GHOST_FEATURE_SHOW_SOCIAL_SHARE', true);
