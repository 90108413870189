/* eslint-disable @typescript-eslint/ban-ts-comment */
import KDBush from 'kdbush';
import geokdbush from 'geokdbush';
import { Coordinates, StateCode } from '../api/location';
import { Appointment, VaccineLocationFeature } from '../api/vaccineLocations';
import { KM_TO_MILE, MILE_TO_KM, USStates } from './locationConstants';
import { TypeaheadLocation } from '../redux/reducers/testingLocations';
import { getActivatedFilters } from './getActivatedFilters';

const rallyUrl = 'https://www.rallyhealth.com';

export const tosKey = 'acceptedToS';

export const getExitUrl = () =>
    document.referrer && window.location.href === document.referrer ? rallyUrl : document.referrer;

export const hasAcceptedTos = () => sessionStorage.getItem(tosKey) === 'true';

let timer: NodeJS.Timer;

export const debounce = (callback: any, time = 250) => {
    return (...args: any) => {
        clearTimeout(timer);
        timer = setTimeout(() => callback(...args), time);
    };
};

// this is straight up from stack overflow
export const formatPhoneNumber = (phoneNumberString: string) => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumberString;
};

export const matchingVaccineLocation = (
    setShowNoAppointment: boolean,
    appointmentType: string[],
    vaccineType: string[]
) => (location: VaccineLocationFeature) => {
    const { properties } = location;
    // Show No Appointment with type checking. Assume null value for appointments_available means no availability
    const excludeNoAppointmentLocation: boolean = !setShowNoAppointment && !properties.appointments_available;
    if (excludeNoAppointmentLocation) return false;

    // Appointment Type
    if (appointmentType.length) {
        if (appointmentType.includes('second_dose')) {
            // Note: Assuming null value for appointments_available_all_doses equivalent to false
            // Note: Assuming null value for appointments_available_2nd_dose_only equivalent to false
            const onlyFirstDosesAvailable: boolean =
                !properties.appointments_available_all_doses && !properties.appointments_available_2nd_dose_only;
            if (onlyFirstDosesAvailable) return false;
        }
        if (appointmentType.includes('first_dose')) {
            // Note: Assuming null value for appointments_available_all_doses equivalent to false
            // Note: Assuming null value for appointments_available_2nd_dose_only equivalent to false
            const onlySecondDosesAvailable: boolean =
                !properties.appointments_available_all_doses && !!properties.appointments_available_2nd_dose_only;
            if (onlySecondDosesAvailable) return false;
        }
    }

    // Vaccine Type
    if (vaccineType.length) {
        const providedVaccines = getActivatedFilters(properties.appointment_vaccine_types || {});

        const hasMatchingVaccine = vaccineType.some((v) => providedVaccines.includes(v));

        if (!hasMatchingVaccine) {
            return false;
        }
    }

    return true;
};

// based on https://github.com/GUI/covid-vaccine-spotter/blob/8ee2347172081cc4b8c35aa74e9965bc44547dd9/website/store/usStates.js
export const withinDistance = (
    locations: VaccineLocationFeature[],
    radius = 50,
    coords: Coordinates
): (VaccineLocationFeature & { distance: string; displayName: string | null })[] => {
    const radiusKM = radius * MILE_TO_KM;

    // @ts-ignore
    const locationsIndex = new KDBush(
        locations,
        (l) => l.geometry.coordinates[0],
        (l) => l.geometry.coordinates[1]
    );

    const filteredLocations: VaccineLocationFeature[] = geokdbush.around(
        locationsIndex,
        coords[0],
        coords[1],
        undefined,
        radiusKM
    );
    return filteredLocations
        .map((feature) => {
            const distance: number | string =
                geokdbush.distance(
                    coords[0],
                    coords[1],
                    feature.geometry.coordinates[0],
                    feature.geometry.coordinates[1]
                ) * KM_TO_MILE;

            const formattedDistance = distance.toFixed(2);

            return {
                ...feature,
                distance: formattedDistance,
                displayName: feature.properties?.name || feature.properties?.provider_brand_name
            };
        })
        .sort((a, b) => parseFloat(a.distance) - parseFloat(b.distance));
};

export const getStateCodeFromTypeaheadLocation = (location: TypeaheadLocation): StateCode | undefined => {
    if (location.address?.state) {
        return location.address?.state;
    }
    const stateFromName = location.name?.split(',')[1]?.trim()?.slice(0, 2);
    if (stateFromName) {
        return stateFromName as StateCode;
    }
    const stateCodeFromName = Object.keys(USStates).find((code) => USStates[code as StateCode] === location.name);
    if (stateCodeFromName) {
        return stateCodeFromName as StateCode;
    }
};

export const getValidAppointments = (appointments: Appointment[]): Appointment[] => {
    return appointments.reduce((soFar: Appointment[], current) => {
        const { time: currentTime, date: currentDate } = current;
        // a date or a time property is needed, otherwise, continue.
        if (!(currentTime || currentDate)) {
            return soFar;
        }
        // if no time property, and date property exists and already included, continue.
        if (!currentTime && currentDate && soFar.find((appt) => appt?.date === currentDate)) {
            return soFar;
        }
        soFar.push(current);
        return soFar;
    }, []);
};

export function filterOutNulls<T>(arr: (T | null)[]): T[] {
    const acc: T[] = [];
    return arr.reduce<T[]>((acc, item) => {
        if (item) {
            return [...acc, item];
        } else {
            return acc;
        }
    }, acc);
}

export * from './locationConstants';
export * from './dateTimeFormatters';
