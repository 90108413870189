import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface State {
    userState: any;
}

export const initialState: State = {
    userState: undefined
};

const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserState: (state, { payload }: PayloadAction<boolean>) => ({ ...state, userState: payload })
    }
});

export const { setUserState } = user.actions;

export default user;
